import React from 'react';

const ContactList = () => (
  <div>
    <h5 className="pt-2 sm:py-2 text-grey font-medium text-xl">
      Contact us
    </h5>
    <ul className="mb-4">
      <li className="text-grey-dark text-sm my-2">United Kingdom: 0333 014 8484</li>
      <li className="text-grey-dark text-sm my-2">United States: +1 3323303222</li>
      <li className="text-grey-dark text-sm my-2">Australia: +61 3 9119 5252</li>
      <li className="text-grey-dark text-sm my-2">New Zealand: +64 9 883 2525</li>
      <li className="text-grey-dark text-sm my-2">
        <a href="mailto:info@bunac.org">info@bunac.org</a>
      </li>
    </ul>
    <ul className="flex space-x-4 mt-10 mb-3 lg:mt-20">
      <li>
        <a
          href="https://twitter.com/BUNACTravel"
          rel="noopener noreferrer"
          className="cbutton"
          target="_blank"
        >
          <img src="/svg/icon-social-twitter.svg" alt="BUNAC blue logotype" />
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/BUNACTravel/"
          rel="noopener noreferrer"
          className="cbutton"
          target="_blank"
        >
          <img src="/svg/icon-social-facebook.svg" alt="BUNAC blue logotype" />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/bunactravel/"
          rel="noopener noreferrer"
          className="cbutton"
          target="_blank"
        >
          <img src="/svg/icon-social-instagram.svg" alt="BUNAC blue logotype" />
        </a>
      </li>
      <li>
        <a
          href="https://www.linkedin.com/company/bunac"
          rel="noopener noreferrer"
          className="cbutton"
          target="_blank"
        >
          <img src="/svg/icon-social-linkedin.svg" alt="BUNAC blue logotype" />
        </a>
      </li>
      <li>
        <a
          href="https://www.tiktok.com/@bunactravel"
          rel="noopener noreferrer"
          className="cbutton"
          target="_blank"
        >
          <img src="/svg/icon-social-tiktok.svg" alt="BUNAC blue logotype" />
        </a>
      </li>
    </ul>
  </div>
);

export { ContactList };
