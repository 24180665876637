import React from 'react';

const Icons = () => (
    <div className="flex justify-end align-middle space-x-6 nowrap">
      <a
        rel="noopener noreferrer"
        href="https://travelaware.campaign.gov.uk/"
        target="_blank"
        className="w-24"
      >
        <img
          src="/images/fco.png"
          alt="FCO"
          width="177"
          height="59"
          decoding="async"
          loading="lazy"
        />
      </a>
      <a
        rel="noopener noreferrer"
        href="http://wyseworkabroad.org/"
        target="_blank"
        className="w-28"
      >
        <img
          src="/images/wyse.png"
          alt="WYSE Work Abroad"
          width="200"
          height="55"
          decoding="async"
          loading="lazy"
        />
      </a>
      <a
        rel="noopener noreferrer"
        href="https://www.betauk.com/item/bunac/"
        target="_blank"
        className="w-20"
      >
        <svg
          id="beta"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 452.46 240.15"
        >
          <title>beta logo</title>
          <path
            className="cls-1"
            d="M182.53,79.13C250.86,65.9,249-2.29,198.71.06,143.32,2.65,119,45,104.89,67.56,82.6,103.15,69.92,151.8,49.45,188,38.91,206.64,18.38,226.83,0,240.15c21.13-4.6,76.09-31.69,100.47-102.7,17.76-51.75,35.62-90.15,54.47-109.8s51.89-16.56,54.93,9.85c2.75,24-41.9,42.07-50.23,45.06a51.18,51.18,0,0,1,6-.65C190.74,80.71,211.94,97,213,119.35S194.6,160.82,169.46,162c-18.21.88-34.37-7.88-42.3-21.27,2.27,11.37,10,28.64,35.58,34.32,39.18,8.7,75.21-15.09,75.26-50.72S182.53,79.13,182.53,79.13Z"
          />
          <path
            className="cls-1"
            d="M255.73,80.94h51v8.12H265.91v33h39.38v7.87H265.78v34.26h42.33v8.12H255.73V80.94Z"
          />
          <path
            className="cls-1"
            d="M309.71,80.94H384.6v8H352.31V172.3h-10V88.94H309.71v-8Z"
          />
          <path
            className="cls-1"
            d="M402.5,80.94h9.65l40.32,91.35h-11l-12.19-28.55H385.49L373.3,172.3h-11Zm23.71,55.57L408.53,95.66A18.54,18.54,0,0,1,407.19,90a13.63,13.63,0,0,1-.14,3.3,8.64,8.64,0,0,1-.8,2.41l-17.68,40.86h37.64Z"
          />
          <path
            className="cls-2"
            d="M99.24,219.62a10.1,10.1,0,0,1-4.61-.88V204.52a9.79,9.79,0,0,1,4.43-.88c3.74,0,5.6,1.72,5.6,4a3.58,3.58,0,0,1-2.89,3.52v0a4.27,4.27,0,0,1,3.41,3.93C105.18,218.25,102.78,219.62,99.24,219.62Zm-0.18-14.36a5,5,0,0,0-1.72.27v5l0.88,0c2.75,0.07,3.68-.88,3.68-2.75C101.9,206.12,100.93,205.26,99.06,205.26ZM98.22,212l-0.88,0v5.58a4.66,4.66,0,0,0,1.81.29c1.85,0,3.16-.74,3.16-2.75S101.16,211.9,98.22,212Z"
          />
          <path
            className="cls-2"
            d="M112.94,209a3.35,3.35,0,0,0-2.57.09V219.4h-2.75V207.88c1.26-.61,2.8-1,5.87-0.86Z"
          />
          <path
            className="cls-2"
            d="M116.76,205.13a1.37,1.37,0,1,1,1.51-1.35A1.43,1.43,0,0,1,116.76,205.13Zm-1.38,14.27V207.23h2.75V219.4h-2.75Z"
          />
          <path
            className="cls-2"
            d="M125.21,219.53a3.08,3.08,0,0,1-3.34-3.3V209h-1.36v-1.76h1.36v-2.69l2.75-.77v3.45h2V209h-2v7.11a1.32,1.32,0,0,0,1.34,1.58,2.19,2.19,0,0,0,.65-0.11v1.71A3.55,3.55,0,0,1,125.21,219.53Z"
          />
          <path
            className="cls-2"
            d="M130.38,205.13a1.37,1.37,0,1,1,1.52-1.35A1.44,1.44,0,0,1,130.38,205.13ZM129,219.4V207.23h2.75V219.4H129Z"
          />
          <path
            className="cls-2"
            d="M137.4,219.6a6.16,6.16,0,0,1-3.37-.88l0.72-1.76a3.78,3.78,0,0,0,2.28.7,1.71,1.71,0,0,0,1.87-1.72c0-1-.54-1.53-1.78-2-2.3-.88-2.78-2.14-2.78-3.41a3.59,3.59,0,0,1,3.86-3.52,5.55,5.55,0,0,1,3.15.86l-0.67,1.6a3.23,3.23,0,0,0-1.95-.67,1.59,1.59,0,0,0-1.74,1.6c0,1,.75,1.49,1.8,1.92,2.38,0.95,2.91,2.17,2.91,3.59C141.74,217.89,140.07,219.6,137.4,219.6Z"
          />
          <path
            className="cls-2"
            d="M151,219.4v-8.56a1.88,1.88,0,0,0-2.14-2.05,3.57,3.57,0,0,0-1.95.49V219.4h-2.75V201.67h2.75v6.08a6,6,0,0,1,2.71-.66c3.07,0,4.14,1.81,4.14,3.68v8.63H151Z"
          />
          <path
            className="cls-2"
            d="M163,219.4V203.86h7.56v1.92h-4.69v4.69H170v1.9h-4.17v5.11h4.69v1.92H163Z"
          />
          <path
            className="cls-2"
            d="M178,219.62c-3.58,0-5.67-2.3-5.67-6.17s2.17-6.35,5.34-6.35a6.55,6.55,0,0,1,1.83.23v-5.67h2.75v17.07A8.68,8.68,0,0,1,178,219.62Zm1.49-10.79a4.31,4.31,0,0,0-1.22-.16c-2.39,0-3.21,2.05-3.21,4.63,0,2.75.88,4.56,3,4.56a2.65,2.65,0,0,0,1.43-.27v-8.76Z"
          />
          <path
            className="cls-2"
            d="M189.9,219.65c-3.25,0-4.72-1.42-4.72-4.11v-8.31h2.75v8.47a1.89,1.89,0,0,0,2.05,2.06,3.54,3.54,0,0,0,1.83-.4V207.23h2.75v11.45A9.91,9.91,0,0,1,189.9,219.65Z"
          />
          <path
            className="cls-2"
            d="M201.87,219.62c-2.78,0-4.81-2.32-4.81-6.35s2.13-6.28,4.84-6.28a4.28,4.28,0,0,1,2.82.84l-0.59,1.56a2.47,2.47,0,0,0-1.62-.52c-1.88,0-2.67,1.69-2.67,4.35s0.93,4.4,2.63,4.4a2.48,2.48,0,0,0,1.65-.54l0.63,1.69A4.19,4.19,0,0,1,201.87,219.62Z"
          />
          <path
            className="cls-2"
            d="M211.28,219.62c-3.32,0-5-1.35-5-3.7,0-3.21,3.09-4.13,6.8-4.49v-0.66c0-1.58-1-2-2.42-2a7.3,7.3,0,0,0-3,.74L207,207.93a9.6,9.6,0,0,1,4.17-.93c2.51,0,4.5,1.06,4.5,4.2v7.54A9.68,9.68,0,0,1,211.28,219.62Zm1.83-6.89c-2.6.32-4.2,0.88-4.2,3.09,0,1.58,1,2.26,2.55,2.26a3.9,3.9,0,0,0,1.65-.34v-5Z"
          />
          <path
            className="cls-2"
            d="M222.5,219.53a3.08,3.08,0,0,1-3.34-3.3V209h-1.35v-1.76h1.35v-2.69l2.76-.77v3.45h2V209h-2v7.11a1.31,1.31,0,0,0,1.33,1.58,2.2,2.2,0,0,0,.66-0.11v1.71A3.59,3.59,0,0,1,222.5,219.53Z"
          />
          <path
            className="cls-2"
            d="M227.68,205.13a1.37,1.37,0,1,1,1.51-1.35A1.43,1.43,0,0,1,227.68,205.13ZM226.3,219.4V207.23h2.75V219.4H226.3Z"
          />
          <path
            className="cls-2"
            d="M236.69,219.62c-3.41,0-5.06-2.71-5.06-6.3s1.65-6.32,5.06-6.32,5.06,2.69,5.06,6.32S240.09,219.62,236.69,219.62Zm0-10.88c-1.95,0-2.33,2.23-2.33,4.56s0.43,4.45,2.33,4.45S239,215.6,239,213.3,238.65,208.74,236.69,208.74Z"
          />
          <path
            className="cls-2"
            d="M251.07,219.4v-8.63a1.89,1.89,0,0,0-2.19-2,4.63,4.63,0,0,0-1.89.34V219.4h-2.75V207.88A11.15,11.15,0,0,1,249,207c3.64,0,4.83,1.77,4.83,3.73v8.67h-2.75Z"
          />
          <path
            className="cls-2"
            d="M261.14,219.62c-3.32,0-5-1.35-5-3.7,0-3.21,3.09-4.13,6.8-4.49v-0.66c0-1.58-1-2-2.42-2a7.27,7.27,0,0,0-3,.74l-0.63-1.58a9.64,9.64,0,0,1,4.18-.93c2.51,0,4.49,1.06,4.49,4.2v7.54A9.65,9.65,0,0,1,261.14,219.62Zm1.83-6.89c-2.6.32-4.2,0.88-4.2,3.09,0,1.58.94,2.26,2.55,2.26a3.89,3.89,0,0,0,1.65-.34v-5Z"
          />
          <path className="cls-2" d="M268.59,219.4V201.67h2.75V219.4h-2.75Z" />
          <path
            className="cls-2"
            d="M285.54,205.78V219.4h-2.87V205.78h-2.89v-1.92h8.67v1.92h-2.91Z"
          />
          <path
            className="cls-2"
            d="M295.24,209a3.35,3.35,0,0,0-2.57.09V219.4h-2.75V207.88c1.26-.61,2.8-1,5.87-0.86Z"
          />
          <path
            className="cls-2"
            d="M301.54,219.62c-3.32,0-5-1.35-5-3.7,0-3.21,3.09-4.13,6.8-4.49v-0.66c0-1.58-1-2-2.41-2a7.27,7.27,0,0,0-3,.74l-0.63-1.58a9.64,9.64,0,0,1,4.18-.93c2.51,0,4.49,1.06,4.49,4.2v7.54A9.65,9.65,0,0,1,301.54,219.62Zm1.83-6.89c-2.6.32-4.2,0.88-4.2,3.09,0,1.58.94,2.26,2.55,2.26a3.89,3.89,0,0,0,1.65-.34v-5Z"
          />
          <path
            className="cls-2"
            d="M314.2,219.4h-2.62l-4-12.17h2.94l2.14,7.86a8.67,8.67,0,0,1,.29,1.67h0a10.38,10.38,0,0,1,.32-1.67l2.19-7.86h2.57Z"
          />
          <path
            className="cls-2"
            d="M321.9,214.32c0.23,2.26,1.31,3.38,3.32,3.38a6.62,6.62,0,0,0,3.07-.67l0.66,1.74a8.3,8.3,0,0,1-4,.86c-3.45,0-5.64-2.23-5.64-6.35s2-6.28,5.2-6.28,5,2.15,4.88,6.23Zm2.49-5.62c-1.61,0-2.71,1.31-2.64,4.22l5-.77C326.78,209.69,325.9,208.7,324.39,208.7Z"
          />
          <path className="cls-2" d="M332,219.4V201.67h2.75V219.4H332Z" />
          <path
            className="cls-2"
            d="M352.75,219.4l-1.2-4h-5l-1.25,4h-2.57l5.15-15.54h2.71l5.13,15.54h-3Zm-3.21-11.13a11.13,11.13,0,0,1-.41-1.87h0a10.17,10.17,0,0,1-.43,1.88L347,213.73h4.15Z"
          />
          <path
            className="cls-2"
            d="M360.4,219.6a6.16,6.16,0,0,1-3.37-.88l0.72-1.76a3.78,3.78,0,0,0,2.28.7,1.71,1.71,0,0,0,1.87-1.72c0-1-.54-1.53-1.78-2-2.3-.88-2.78-2.14-2.78-3.41a3.59,3.59,0,0,1,3.86-3.52,5.55,5.55,0,0,1,3.16.86l-0.67,1.6a3.24,3.24,0,0,0-1.95-.67,1.59,1.59,0,0,0-1.74,1.6c0,1,.75,1.49,1.8,1.92,2.38,0.95,2.91,2.17,2.91,3.59C364.73,217.89,363.06,219.6,360.4,219.6Z"
          />
          <path
            className="cls-2"
            d="M369.54,219.6a6.18,6.18,0,0,1-3.37-.88L366.9,217a3.8,3.8,0,0,0,2.28.7,1.72,1.72,0,0,0,1.88-1.72c0-1-.54-1.53-1.79-2-2.3-.88-2.78-2.14-2.78-3.41a3.6,3.6,0,0,1,3.86-3.52,5.58,5.58,0,0,1,3.16.86l-0.68,1.6a3.21,3.21,0,0,0-1.94-.67,1.59,1.59,0,0,0-1.74,1.6c0,1,.75,1.49,1.81,1.92,2.37,0.95,2.91,2.17,2.91,3.59C373.88,217.89,372.2,219.6,369.54,219.6Z"
          />
          <path
            className="cls-2"
            d="M380.9,219.62c-3.41,0-5.06-2.71-5.06-6.3s1.64-6.32,5.06-6.32,5.06,2.69,5.06,6.32S384.31,219.62,380.9,219.62Zm0-10.88c-1.94,0-2.32,2.23-2.32,4.56s0.43,4.45,2.32,4.45,2.33-2.15,2.33-4.45S382.86,208.74,380.9,208.74Z"
          />
          <path
            className="cls-2"
            d="M392.8,219.62c-2.78,0-4.81-2.32-4.81-6.35s2.13-6.28,4.84-6.28a4.28,4.28,0,0,1,2.82.84l-0.59,1.56a2.47,2.47,0,0,0-1.62-.52c-1.88,0-2.67,1.69-2.67,4.35s0.93,4.4,2.63,4.4a2.48,2.48,0,0,0,1.65-.54l0.63,1.69A4.19,4.19,0,0,1,392.8,219.62Z"
          />
          <path
            className="cls-2"
            d="M399.3,205.13a1.37,1.37,0,1,1,1.52-1.35A1.44,1.44,0,0,1,399.3,205.13Zm-1.37,14.27V207.23h2.75V219.4h-2.75Z"
          />
          <path
            className="cls-2"
            d="M408.08,219.62c-3.32,0-5-1.35-5-3.7,0-3.21,3.09-4.13,6.8-4.49v-0.66c0-1.58-1-2-2.42-2a7.3,7.3,0,0,0-3,.74l-0.63-1.58A9.59,9.59,0,0,1,408,207c2.51,0,4.5,1.06,4.5,4.2v7.54A9.68,9.68,0,0,1,408.08,219.62Zm1.83-6.89c-2.6.32-4.2,0.88-4.2,3.09,0,1.58,1,2.26,2.55,2.26a3.91,3.91,0,0,0,1.65-.34v-5Z"
          />
          <path
            className="cls-2"
            d="M419.3,219.53a3.08,3.08,0,0,1-3.34-3.3V209h-1.35v-1.76H416v-2.69l2.76-.77v3.45h2V209h-2v7.11a1.31,1.31,0,0,0,1.33,1.58,2.21,2.21,0,0,0,.66-0.11v1.71A3.59,3.59,0,0,1,419.3,219.53Z"
          />
          <path
            className="cls-2"
            d="M424.48,205.13a1.37,1.37,0,1,1,1.51-1.35A1.43,1.43,0,0,1,424.48,205.13ZM423.1,219.4V207.23h2.75V219.4H423.1Z"
          />
          <path
            className="cls-2"
            d="M433.49,219.62c-3.41,0-5.06-2.71-5.06-6.3s1.65-6.32,5.06-6.32,5.06,2.69,5.06,6.32S436.9,219.62,433.49,219.62Zm0-10.88c-1.95,0-2.33,2.23-2.33,4.56s0.43,4.45,2.33,4.45,2.32-2.15,2.32-4.45S435.45,208.74,433.49,208.74Z"
          />
          <path
            className="cls-2"
            d="M447.88,219.4v-8.63a1.89,1.89,0,0,0-2.19-2,4.68,4.68,0,0,0-1.9.34V219.4H441V207.88a11.2,11.2,0,0,1,4.77-.88c3.63,0,4.83,1.77,4.83,3.73v8.67h-2.75Z"
          />
        </svg>
      </a>
      <a
        href="https://www.wystc.org/2017/09/28/2017-global-youth-travel-awards-winners/"
        target="_blank"
        rel="noopener noreferrer"
        className="w-14"
      >
        <img
          src="/images/global-youth-travel-awards.png"
          alt="WYSTC Youth Travel Awards"
          width="100"
          height="85"
          loading="lazy"
          decoding="async"
        />
      </a>
    </div>
);

export { Icons };
