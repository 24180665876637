import React from 'react';
import styled from 'styled-components';
import { ContactList } from './Footer/ContactList';
import { LogotypePrimary } from './Footer/LogotypePrimary';
import { UsefulLinks } from './Footer/UsefulLinks';
import { Newsletter } from './Footer/Newsletter';
import { Copyright } from './Footer/Copyright';
import { Legallinks } from './Footer/Legallinks';
import { Icons } from './Footer/Icons';
import ReviewsCarousel from '../reviewsBanners/ReviewsCarousel';
import { TypeFormAskAQuestion } from '../Layout/3rdParty/TypeFormAskAQuestion copy';

const Footer = () => {
  return (
    <Container className="bg-white p-0">
      <img
        className="absolute bottom-144 -right-24 z-10 invisible xl:visible"
        src="/svg/blue-wash.svg"
        alt="Blue brush stroke positioned on the right middle side"
        loading="lazy"
      />
      <img
        className="absolute bottom-180 -left-44 z-10 opacity-30 invisible xl:visible"
        src="/svg/blue-wash-left.svg"
        alt="Blue brush stroke positioned on the left top side"
      />
      <div className="container-fluid relative grid grid-cols-2 px-4 sm:px-16 lg:px-32 mt-24">
        <div className="absolute inset-0 top-24 visible xl:invisible">
          <img
            className="absolute inset-0 h-full object-cover"
            src="/images/footer-img-sm.jpg"
            alt="Landscape with mountains and a blue lake"
            loading="lazy"
          />
        </div>
        <img
          className="absolute inset-0 invisible xl:visible h-full object-cover"
          src="/images/footer-img.jpg"
          alt="young girl looking up in a blue background"
          loading="lazy"
        />
        <div
          className="relative w-full md:pb-10 mb-12 md:mb-24 col-span-2 xl:col-span-1 col-start-1 xl:col-start-2 xl:mb-0 -top-44 pt-44 md:-top-24 md:pt-24"
          id="contact-form"
        >
          <div className="flex-col text-center h-full bg-blue xs:px-1 py-1 rounded-xl">
            <TypeFormAskAQuestion />
          </div>
        </div>
        <div className="col-span-2 visible xl:hidden">
          <img
            className="relative mx-auto"
            src="/images/footer-heritage-icon.png"
            alt="Icons of famous places in the world"
            loading="lazy"
          />
        </div>
      </div>
      <div className="bg-grey-light container-fluid grid grid-cols-12 gap-4 flex-wrap px-6 lg:px-32 pb-6">
        <div className="col-span-12 pt-16 pb-8 sm:pt-12">
          <LogotypePrimary />
        </div>
        <div className="col-span-12">
          <ReviewsCarousel component="footer" />
        </div>
        <div className="col-span-12">
          <Divider></Divider>
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-3">
          <ContactList />
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-3">
          <UsefulLinks />
        </div>
        <div className="col-span-12 lg:col-span-6">
          <Newsletter />
          <Copyright />
          <Legallinks />
        </div>
      </div>
      <div className="px-4 sm:px-16 lg:px-32 py-4 bg-navy">
        <Icons />
      </div>
    </Container>
  );
};

const Container = styled.footer``;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfdfdf;
  margin-top: -1rem;
  margin-bottom: 1rem;
`;

export { Footer };
