import React from 'react';
import styled from 'styled-components';

const LevelIndicator = ({ title, onClick, index, ...restProps }) => {
  return (
    <Wrapper>
      <Button onClick={onClick}>{title}</Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
`;

const Button = styled.button`
    font-size: 24px;
    font-weight: 600;
    color: #C9C9C9;
    padding: 22px 0px 12px 0px;
`;

export default LevelIndicator;
