import React from 'react';
import styled from 'styled-components';
import levelTitleIcon from '../../../icons/level-home-icon.svg';

const LevelIndicatorHome = ({ title, onClick, index, ...restProps }) => {
  return (
      <Wrapper>
      <Button onClick={onClick}>
          <ButtonIcon src={levelTitleIcon} alt="Home icon" />
          {title}
        </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: -4.5rem;
`;

const Button = styled.button`
    display: flex;
    font-size: 18px;
    font-weight: 500;
    color: #C9C9C9;
    padding: 12px 0px;
`;

const ButtonIcon = styled.img`
    margin-right: 8px;
`;


export default LevelIndicatorHome;
