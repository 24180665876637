import React, { useState } from 'react';
import { useMyBunac } from '../../../hooks/useMyBunac';

const FORM_ID = 'newsletter';
const PIPELINE_ID = 29;
const PIPEDRIVE_FIELD_KEY_EMAIL = 'email';

const Newsletter = () => {
  const { request } = useMyBunac();
  const [email, setEmail] = useState('');
  const [wasSent, setWasSent] = useState(false);

  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const requestParams = {
      path: '/form',
      method: 'POST',
      params: {
        id: FORM_ID,
        pipelineid: PIPELINE_ID,
        fields: JSON.stringify({
          [PIPEDRIVE_FIELD_KEY_EMAIL]: email,
        }),
        notes: JSON.stringify([
          {
            model: 'deal',
            html: `Stay Posted: ${email}`,
          },
        ]),
      },
    };
    try {
      await request(requestParams);
      setWasSent(true);
    } catch (error) {
      //
    }
  };

  return (
    <div className="flex-1">
      <h5 className="pt-2 sm:py-2 text-grey font-medium text-xl">
        Stay posted on everything BUNAC
      </h5>
      {wasSent && (
        <p className='text-grey-dark text-sm font-medium'>
          Thanks for signing up. Keep an eye on your emails for the latest
          travel inspiration.
        </p>
      )}
      {!wasSent && (
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-12 gap-2 mt-2"
        >
          <input
            name="email"
            onChange={handleChange}
            type="email"
            placeholder="Enter your email"
            required={true}
            className="col-span-12 sm:col-span-9 h-10 bg-white mb-2 sm:mb-4 py-2 placeholder-grey text-grey text-xs font-medium pl-4 rounded-full"
          />
          <button
            name="submit"
            className="col-span-12 sm:col-span-3 h-10 px-6 bg-navy rounded-full text-white text-xs font-semibold uppercase"
          >
            <div className='inline-flex m-auto align-middle items-center'>
              <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="8" height="19.246" viewBox="0 0 10.623 19.246">
                <path id="Caminho_63" data-name="Caminho 63" d="M-3256.945-9565l8.916,8.916-8.916,8.916" transform="translate(3257.652 9565.707)" fill="none" stroke="#fff5a8" stroke-linejoin="round" stroke-width="2"/>
              </svg>
              <span className='whitespace-nowrap'>Sign Up</span>
            </div>
          </button>
        </form>
      )}
    </div>
  );
};

export { Newsletter };
