import React from 'react';

const Legallinks = () => (
    <div className="md:flex-1 mt-8">
        <ul className='flex space-x-3 md:space-x-4 whitespace-nowrap flex-wrap'>
            <li className="my-2">
                <a className=' text-grey-dark text-xs hover:text-blue hover:font-medium transition-all' href="/privacy-policy">Privacy policy</a>
            </li>
            <li className="my-2">
                <a className=' text-grey-dark text-xs hover:text-blue hover:font-medium transition-all' href="/cookie-policy">Cookie policy</a>
            </li>
            <li className="my-2">
                <a className=' text-grey-dark text-xs hover:text-blue hover:font-medium transition-all' href="/terms-and-conditions">Terms &amp; conditions</a>
            </li>
            <li className="my-2">
                <p className='text-grey text-xs my-auto'>© BUNAC travel services</p>
            </li>
        </ul>
    </div>
);

export {Legallinks};