import React, { useEffect } from 'react';
import styled from 'styled-components';

const ReviewsRibbonMobile = () => {
    useEffect(() => {
        const ribbonScript = document.createElement('script');
        ribbonScript.async = true;
        ribbonScript.innerHTML = `"undefined"==typeof reviewsBadgeRibbon&&(reviewsBadgeRibbon=function(e,t){var n=document.getElementById(e),d="https://widget.reviews.io/badge-ribbon/widget",o="elementId="+e+"&version=undefined&";for(i in t)o.length>0&&(o+="&"),"object"!=typeof t[i]?o+=i+"="+encodeURIComponent(t[i]):o+=i+"="+encodeURIComponent(JSON.stringify(t[i]));d+="?"+o;var a=document.createElement("IFRAME");a.setAttribute("src",d),a.setAttribute("frameborder",0),a.width="100%",a.style.minWidth="object"==typeof t&&"small"===t.size?"320px":"380px",a.title="Reviews Badge Ribbon Widget",n.appendChild(a);var r=function(t){try{var i=JSON.parse(t.data);if(void 0!=i.action)switch(i.action){case"widget-resize":i.elementId==e&&(a.height=i.height)}}catch(e){}};window.addEventListener?addEventListener("message",r,!1):attachEvent("onmessage",r)},"function"==typeof reviewsBadgeRibbonCallback&&reviewsBadgeRibbonCallback());reviewsBadgeRibbon('badge-ribbon-mobile', {store: 'bunac.org-ePK6OAB', size: 'small',})`;

        document.body.appendChild(ribbonScript);

        return () => {
            document.body.removeChild(ribbonScript);
        };
    }, []);

    return (
        <>
            <ReviewsMobile id="badge-ribbon-mobile"></ReviewsMobile>
        </>
    );
};

const ReviewsMobile = styled.div`
height: 90px !important
padding-top: 28px;
padding-left: 14%;

@media screen and (min-width: 577px){
    display: none;
}

@media screen and (max-width: 576px) {
    iframe {
        padding-top: 28px;
        height: 90px;
      }
  }
`;

export default ReviewsRibbonMobile;
