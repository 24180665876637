import React from 'react';

const Copyright = () => (
  <div className="flex-1 text-grey mt-10">
    <p className='text-xs leading-relaxed'>
      The BUNAC® logo and many of the programme names on this website are
      registered trademarks in the USA, the United Kingdom and in other
      jurisdictions. Company registration number: 1391030. Trading as BUNAC
      Travel Services Limited, 5 Richbell Place, London, WC1N 3LA, United Kingdom.
    </p>
  </div>
);

export { Copyright };
