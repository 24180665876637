import React from 'react';

const UsefulLinks = () => (
  <div className="md:flex-1">
    <h5 className="pt-2 sm:py-2 text-grey font-medium text-xl">
      Useful links
    </h5>
    <ul>
      <li className="my-2">
        <a className='text-grey-dark text-sm hover:text-blue hover:font-medium transition-all' href="/for-organisations/partner-with-us">Partner with BUNAC</a>
      </li>
      <li className="my-2">
        <a className='text-grey-dark text-sm hover:text-blue hover:font-medium transition-all' href="/information-for-parents">Information for parents</a>
      </li>
      <li className="my-2">
        <a className='text-grey-dark text-sm hover:text-blue hover:font-medium transition-all' href="/careers">Careers</a>
      </li>
      <li className="my-2">
        <a className='text-grey-dark text-sm hover:text-blue hover:font-medium transition-all' href="/contact">Contact us</a>
      </li>
    </ul>
  </div>
);

export { UsefulLinks };
