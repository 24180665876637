import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export const TypeFormAskAQuestion = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//embed.typeform.com/next/embed.js';
    script.async = true;

    document.head.appendChild(script);
  }, []);
  return (
      <div
        data-tf-live="01J2K8ZA1VN8CCZV34ZPY1M8SN"
      />
  );
};
