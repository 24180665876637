import { graphql, Link as _Link, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import formatSlug from '../../../lib/slugFormatter';
import styled from 'styled-components';
import LevelIndicator from './LevelIndicator';
import LevelIndicatorHome from './LevelIndicatorHome';
import levelTitleIcon from '../../../icons/level-title-icon.svg';
import linkChevronIcon from '../../../icons/link-chevron-icon.svg';
import destinationsIcon from '../../../icons/nav-destinations.svg';
import workingHolidaysIcon from '../../../icons/nav-working-holidays.svg';
import internshipIcon from '../../../icons/nav-internship.svg';
import summerCampIcon from '../../../icons/nav-summer-camp.svg';
import volunteerIcon from '../../../icons/nav-volunteer.svg';
import teachingIcon from '../../../icons/nav-teaching.svg';
import blogsIcon from '../../../icons/nav-blogs.svg';
import aboutIcon from '../../../icons/nav-about.svg';
import contactIcon from '../../../icons/nav-contact.svg';
import loginIcon from '../../../icons/nav-login.svg';
const MAIN_NAVIGATION_ID = 'main';

const Navigation = (props) => {
  const data = useStaticQuery(graphql`
    query MainNavigationQuery {
      allStrapiProgrammes {
        edges {
          node {
            Title
            Category {
              Title
              id
            }
          }
        }
      }
      allStrapiContinents {
        edges {
          node {
            Title
            strapiId
          }
        }
      }
      allStrapiCountries {
        edges {
          node {
            Title
            Continent {
              id
            }
            strapiId
          }
        }
      }
      allStrapiCategories {
        edges {
          node {
            strapiId
            Title
            Order
          }
        }
      }
      allStrapiPages {
        edges {
          node {
            strapiId
            Slug
            Display_Title
            SubPages {
              id
              Slug
              Display_Title
            }
          }
        }
      }
      allStrapiNavigations {
        edges {
          node {
            Title
            Navigation_Id
            strapiId
            Pages {
              Pages {
                id
                Slug
                Display_Title
              }
            }
          }
        }
      }
    }
  `);

  const rootSlug = formatSlug(['destinations']);
  const destinationsOptions = [
    'Australia',
    'Canada',
    'New Zealand',
    'United States',
    'UK',
    'Japan',
    'Ireland',
    'South Korea'
  ];
  const navigationEntries = [
    {
      Title: 'Destinations',
      slug: rootSlug,
      children: data.allStrapiCountries.edges
        .map((elem) => elem.node)
        .filter((country) => destinationsOptions.includes(country.Title))
        .map((country) => {
          return {
            ...country,
            slug: formatSlug([rootSlug, country.Title]),
          };
        })
        .sort((country1, country2) =>
          country1.Title < country2.Title ? -1 : 1
        ),
    },
    ...data.allStrapiCategories.edges
      .map((elem) => elem.node)
      .map((category) => {
        return {
          ...category,
          slug: formatSlug([category.Title]),
        };
      })
      .sort(
        (category1, category2) =>
          (category1.Order || 999) - (category2.Order || 999)
      ),
    ...data.allStrapiNavigations.edges
      .map((elem) => elem.node)
      .find((elem) => elem.Navigation_Id === MAIN_NAVIGATION_ID)
      .Pages.Pages.map((page) => {
        if (page.Display_Title != 'Contact us') {
          return {
            ...page,
            Title: page.Display_Title,
            slug: page.Slug || formatSlug([page.Display_Title]),
            children: data.allStrapiPages.edges
              .map((elem) => elem.node)
              .find((elem) => elem.strapiId === page.id)
              .SubPages.map((subPage) => {
                if (subPage.Display_Title === 'BUNAC Events') {
                  return {
                    ...subPage,
                    Title: subPage.Display_Title,
                    slug: 'https://www.eventbrite.com/cc/bunac-online-events-83569?utm-campaign=social&utm-content=creatorshare&utm-medium=discovery&utm-term=odclsxcollection&utm-source=cp&aff=escb',
                  };
                } else {
                  return {
                    ...subPage,
                    Title: subPage.Display_Title,
                    slug: subPage.Slug || formatSlug([subPage.Display_Title]),
                  };
                }
              }),
          };
        } else {
          return {
            Title: '',
          };
        }
      }),
    {
      Title: 'Blogs',
      slug: formatSlug(['blogs']),
    },
    ...data.allStrapiNavigations.edges
      .map((elem) => elem.node)
      .find((elem) => elem.Navigation_Id === MAIN_NAVIGATION_ID)
      .Pages.Pages.map((page) => {
        if (page.Display_Title == 'Contact us') {
          return {
            ...page,
            Title: page.Display_Title,
            slug: page.Slug || formatSlug([page.Display_Title]),
            children: data.allStrapiPages.edges
              .map((elem) => elem.node)
              .find((elem) => elem.strapiId === page.id)
              .SubPages.map((subPage) => {
                return {
                  ...subPage,
                  Title: subPage.Display_Title,
                  slug: subPage.Slug || formatSlug([subPage.Display_Title]),
                };
              }),
          };
        } else {
          return {
            Title: '',
          };
        }
      }),
    {
      Title: 'Login',
      slug: 'https://inspire.hanovercrm.com/users/sign_in',
    },
  ];

  const [currNavigationEntries, setCurrNavigationEntries] =
    useState(navigationEntries);
  const [breadcrumbPath, setBreadcrumbPath] = useState([]);
  const [canNavigateBack, setCanNavigateBack] = useState(false);

  const resetBreadcrumb = () => {
    setBreadcrumbPath([]);
    setCurrNavigationEntries(navigationEntries);
  };

  const selectEntry = (entry) => {
    if (!entry.children || !entry.children.length) {
      return;
    }
    const newBreadcrumbPath = [...breadcrumbPath];

    if (breadcrumbPath.length === 0) {
      const homeEntry = {
        Title: 'Home',
        slug: '/',
        children: navigationEntries,
      };
      newBreadcrumbPath.push(homeEntry);
    }
    newBreadcrumbPath.push(entry);
    setBreadcrumbPath(newBreadcrumbPath);
    setCurrNavigationEntries(entry.children);
  };

  const goBack = () => {
    const previousNavigationIndex = breadcrumbPath.length - 1;
    goBackToIndex(previousNavigationIndex);
  };

  const goBackToIndex = (index) => {
    if (index <= 0) {
      return resetBreadcrumb();
    }
    const newBreadcrumbPath = breadcrumbPath.slice(0, index);
    setCurrNavigationEntries(
      newBreadcrumbPath[newBreadcrumbPath.length - 1].children
    );
    setBreadcrumbPath(newBreadcrumbPath);
  };

  useEffect(() => {
    if (breadcrumbPath.length > 1) {
      setCanNavigateBack(true);
    } else {
      setCanNavigateBack(false);
    }
  }, [breadcrumbPath]);

  return (
    <>
      <MainNavigation id="mp-menu" className="mp-menu active">
        <Wrapper className="nav-wrapper">
          <Level className="mp-level level_0 active">
            <MenuContainer>
              {canNavigateBack && (
                <>
                  {/* Breadcrumbs */}
                  {Array.isArray(breadcrumbPath) &&
                    breadcrumbPath.map((entry, index) => {
                      const revertIndex = breadcrumbPath.length - (index + 1);
                      if (index + 1 !== breadcrumbPath.length && index !== 0) {
                        return (
                          <LevelIndicator
                            title={entry.Title}
                            key={entry.Title}
                            index={revertIndex - 1}
                            onClick={() => goBackToIndex(index + 1)}
                          />
                        );
                      } else if (index === 0) {
                        return (
                          <LevelIndicatorHome
                            title={entry.Title}
                            key={entry.Title}
                            index={revertIndex - 1}
                            onClick={() => goBackToIndex(index + 1)}
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  <LevelTitle>
                    <NavigationLink
                      title={breadcrumbPath[breadcrumbPath.length - 1].Title}
                      slug={breadcrumbPath[breadcrumbPath.length - 1].slug}
                    />
                  </LevelTitle>
                </>
              )}
              {currNavigationEntries.map((entry) => {
                if (!entry.children || !entry.children.length) {
                  return (
                    <>
                      {entry.Title == 'Volunteer' ||
                      entry.Title == 'Teaching' ||
                      entry.Title == '' ? (
                        <></>
                      ) : (
                        <MenuItem key={entry.Title}>
                          {entry.Title == 'Working Holidays' ? (
                            <img
                              class="menu-item-icon"
                              src={workingHolidaysIcon}
                              alt="work bag icon"
                            />
                          ) : (
                            ''
                          )}
                          {entry.Title == 'Internships Abroad' ? (
                            <img
                              class="menu-item-icon"
                              src={internshipIcon}
                              alt="company building icon"
                            />
                          ) : (
                            ''
                          )}
                          {entry.Title == 'Summer Camp' ? (
                            <img
                              class="menu-item-icon"
                              src={summerCampIcon}
                              alt="sun icon"
                            />
                          ) : (
                            ''
                          )}
                          {entry.Title == 'Blogs' ? (
                            <img
                              class="menu-item-icon"
                              src={blogsIcon}
                              alt="bookmark icon"
                            />
                          ) : (
                            ''
                          )}
                          {entry.Title == 'Contact us' ? (
                            <img
                              class="menu-item-icon"
                              src={contactIcon}
                              alt="phone icon"
                            />
                          ) : (
                            ''
                          )}
                          {entry.Title == 'Login' ? (
                            <img
                              class="menu-item-icon"
                              src={loginIcon}
                              alt="login icon"
                            />
                          ) : (
                            ''
                          )}
                          <NavigationLink
                            title={entry.Title}
                            slug={entry.slug}
                          />
                        </MenuItem>
                      )}
                    </>
                  );
                }
                return (
                  <MenuItem key={entry.Title}>
                    {entry.Title == 'Destinations' ? (
                      <img
                        class="menu-item-icon"
                        src={destinationsIcon}
                        alt="world icon"
                      />
                    ) : (
                      ''
                    )}
                    {entry.Title == 'About BUNAC' ? (
                      <img
                        class="menu-item-icon"
                        src={aboutIcon}
                        alt="group support contact"
                      />
                    ) : (
                      ''
                    )}
                    <NavigationCategory
                      title={entry.Title}
                      slug={entry.slug}
                      entry={entry}
                      onSelect={selectEntry}
                    />
                  </MenuItem>
                );
              })}
            </MenuContainer>
          </Level>
        </Wrapper>
      </MainNavigation>
      <Overlay />
    </>
  );
};

const NavigationLink = ({ slug, title }) => {
  return <Link to={slug}>{title}</Link>;
};

const NavigationCategory = ({ slug, entry, title, onSelect }) => {
  return (
    <LinkImposter
      href="#"
      className="main-menu-has-children"
      onClick={() => onSelect(entry)}
    >
      {title}
    </LinkImposter>
  );
};

const MainNavigation = styled.nav`
  right: 0;
  position: absolute;
  background-color: #fff;
  top: 0;
  z-index: 110;
  height: 100%;
  padding-top: 100px;
  opacity: 1;
  visibility: visible;
  -webkit-box-shadow: -8px -8px 16px rgba(0, 0, 0, 0.2);
  box-shadow: -8px -8px 16px rgba(0, 0, 0, 0.2);

  transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 440px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0rem 3rem;
  z-index: 99;
  position: relative;
`;

const NavigateBack = styled.button`
  cursor: pointer;
  color: #222;
  font-size: 16px;
  border: 0;
  padding: 16px 32px 16px 32px;
  text-transform: uppercase;
  display: block;
  cursor: pointer;
  outline: none;
`;

const MenuContainer = styled.ul``;

const Level = styled.div`
  position: fixed;
  transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  -webkit-transition: opacity 0.2s ease-out;
  -o-transition: opacity 0.2s ease-out;

  @media screen and (max-width: 1440px) and (min-width: 1024px) {
    position: initial;
  }
`;

const MenuItem = styled.li`
  display: flex;
  transition: all 0.25s ease;
  opacity: 1;
  margin-left: 0;
  transition-delay: 80ms;
  -webkit-transition: all 0.25s ease;
  border-bottom: 1px solid #f2f0f0;
  width: 330px;
  padding: 22px 0px;

  @media screen and (max-width: 767px) {
    width: calc(100vw - 110px);
  }
  img {
    margin-right: 8px;
    transition: All 0.05s ease-out;
    -moz-transition: All 0.05s ease-out;
    -webkit-transition: All 0.05s ease-out;
    -o-transition: All 0.05s ease-out;
  }
  &:hover {
    a,
    a:link,
    a:visited {
      color: #0094a5;
    }
    .menu-item-icon {
      filter: invert(1) sepia(1) saturate(9) hue-rotate(157deg);
    }
  }
  a,
  a:link,
  a:visited {
    color: #222;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    display: block;
    position: relative;
    transition: All 0.2s ease-out;
    -moz-transition: All 0.2s ease-out;
    -webkit-transition: All 0.2s ease-out;
    -o-transition: All 0.2s ease-out;
  }
`;

const LinkImposter = styled.a`
  &:after {
    content: '';
    width: 14px;
    height: 14px;
    background-image: url(${linkChevronIcon});
    background-repeat: no-repeat;
    display: inline-flex;
    margin-left: 8px;
  }
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
`;

const Link = styled(_Link)`
  @media screen and (max-width: 767px) {
    font-size: 20px;
  }
`;

const LevelTitle = styled(MenuItem)`
  margin-bottom: 0;
  a,
  a:link,
  a:visited {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    color: #222;
    height: auto;
    &:hover {
      color: initial;
    }

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url(${levelTitleIcon});
      display: inline-flex;
      margin-left: 8px;
    }
  }
`;

const Overlay = styled.div`
  background: rgba(34, 34, 34, 0.5);
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: background 0.2s ease-out;
  -moz-transition: background 0.2s ease-out;
  -webkit-transition: background 0.2s ease-out;
  -o-transition: background 0.2s ease-out;
`;

export default Navigation;
