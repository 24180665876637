function formatSlug(pathList, parentPath) {
  const formattedPath = pathList.map(slugifyText).join('/');
  const rootPrefix = formattedPath.startsWith('/') ? '' : '/';
  return `${parentPath || ''}${rootPrefix}${formattedPath}`;
}

function slugifyText(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
}

module.exports = formatSlug;
