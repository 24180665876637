import React, { FunctionComponent, useEffect } from 'react';
import { carouselScript_function } from './carouselScript';
import styled from 'styled-components';

interface CarouselProps {
  component: string
}

const ReviewsCarousel: FunctionComponent<CarouselProps> = ({ component }) => {
  useEffect(() => {
    const scriptCarousel = document.createElement('script');

    scriptCarousel.innerHTML = carouselScript_function.replace("DIV_NAME", `reviewsio-carousel-widget_${component}`);

    document.body.appendChild(scriptCarousel);

    return () => {
      document.body.removeChild(scriptCarousel);
    };
  }, [component]);

  return (
    <>
      <div id={`reviewsio-carousel-widget_${component}`}></div>
    </>
  );
};

const Carousel = styled.div`

  @media screen and (max-width: 767px) {
    .CarouselWidget__header {
      padding-left: 0rem !important;
    }
  }
  .header__inner {
    justify-content: flex-start !important;
    padding-left: 0rem !important;
    padding-top: 0rem !important;
    align-items: baseline !important;
  }
`;

export default ReviewsCarousel;
