import React, { useEffect } from 'react';
import styled from 'styled-components';

const ReviewsRibbon = () => {
  useEffect(() => {
    const ribbonScript = document.createElement('script');
    ribbonScript.async = true;
    ribbonScript.innerHTML = `"undefined"==typeof reviewsBadgeRibbon&&(reviewsBadgeRibbon=function(e,t){var n=document.getElementById(e),d="https://widget.reviews.io/badge-ribbon/widget",o="elementId="+e+"&version=undefined&";for(i in t)o.length>0&&(o+="&"),"object"!=typeof t[i]?o+=i+"="+encodeURIComponent(t[i]):o+=i+"="+encodeURIComponent(JSON.stringify(t[i]));d+="?"+o;var a=document.createElement("IFRAME");a.setAttribute("src",d),a.setAttribute("frameborder",0),a.width="100%",a.style.minWidth="object"==typeof t&&"small"===t.size?"320px":"380px",a.title="Reviews Badge Ribbon Widget",n.appendChild(a);var r=function(t){try{var i=JSON.parse(t.data);if(void 0!=i.action)switch(i.action){case"widget-resize":i.elementId==e&&(a.height=i.height)}}catch(e){}};window.addEventListener?addEventListener("message",r,!1):attachEvent("onmessage",r)},"function"==typeof reviewsBadgeRibbonCallback&&reviewsBadgeRibbonCallback());reviewsBadgeRibbon('badge-ribbon', {store: 'bunac.org-ePK6OAB', size: 'small',})`;

    document.body.appendChild(ribbonScript);

    return () => {
      document.body.removeChild(ribbonScript);
    };
  }, []);

  return (
    <>
      <Reviews id="badge-ribbon"></Reviews>
    </>
  );
};

const Reviews = styled.div `
    position: relative;
    left: 2rem;
    bottom: 0.4rem;
    margin-right: auto;
    height: 50px;

    iframe {
      height:64px;
    }

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

export default ReviewsRibbon;
